.mw_900{
    min-width: 1000px;
}
.over_auto{
    overflow-x: inherit
}
@media only screen and (max-width: 600px) {
    .over_auto{
        overflow-x: scroll;
    }
    
  }