@mixin img_bg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}
@mixin promo_img{
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 250px;
    box-shadow: 0 0 10px lighten($base, 20);
}