@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Raleway&display=swap');


.font_special {
    font-family: 'Poppins', sans-serif;
}

.font_small {
    font-family: 'Raleway', sans-serif;
}

.font_shadow {
    text-shadow: 0 0 5px #000;
}

.font_gold{
    color: $link;
}