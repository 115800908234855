.after_img{
    position: relative;
    &::after{
        @include img_bg;
        background-image: $img_after;
        content: "";
        position: absolute;
        top: 20px;
        left: -1%;
        width:100%;
        height: 290px;
        z-index: -123344;
        border-radius: 10px;
    }
}
@media only screen and (max-width: 600px) {
    .after_img{
        position: relative;
        &::after{
            @include img_bg;
            background-image: $img_after;
            content: "";
            position: absolute;
            top: 20px;
            left: -1%;
            width:100%;
            height: 200px;
            z-index: -123344;
            border-radius: 10px;
        }
    }
  }