.btn_principal {
    background: $btns;
    color: #fff;

    &:hover {
        background: $btns_hover;
        color: #fff;
    }
}
.btn_white{
    background: $gradiant_blanco;
    color: $base;
    font-weight: 900;

    &:hover {
        background: $gradiant_blanco_hover;
        color: $base_to_dark;
    }
}
.btn_border_gradiant {
    background-clip: padding-box;
    border: solid $img_after transparent;
    background: #fff;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -3px;
        background: $img_after;
        border-radius: 25px;
    }
}
.btn_border_gradiant_2 {
    background-clip: padding-box;
    color:#fff;
    background: #000;
    position: relative;
    &::before {
        content: '';
        color:#fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -3px;
        background: $img_after;
        border-radius: 25px;
    }
}

.btn_slider {
    color: #fff;
    border: 1px solid #fff;
    width: 40px;
    padding: 4px;
    font-size: 2rem;

    &:hover {
        color: #fff;
    }
}
