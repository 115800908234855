.bg_base {
    background: $base;
}

.bg_gradiant_base_dark {
    background: $base_to_dark;
}

.bg_brown {
    background: $brown;
}

.bg_grey {
    background: $grey;
}
.bg_grey_ligth {
    background: lighten($grey, 20);
}

.divider {
    width: 100%;
    height: 2px;
    background: $link;
}

.divider_grey {
    width: 100%;
    height: 1px;
    background: darken(#fff, 80);
}



//links
.navbar {
    color: #fff!important;
    --bs-navbar-hover-color: $base;
    --bs-navbar-active-color: $base;
    --bs-navbar-focus-color: $base;

}

.nav-link {
    color: #fff;

    &:hover {
        color: $link;
    }
}

a {
    color: $link;
    text-decoration: none;

    &:hover {
        color: $link !important;
    }
}

.sp1_home,
.sp1_sportsbook,
.span_poker,
.span_about {
    @include img_bg;
    background-image: url('../img/general/sp_2.jpg');
    background-attachment: fixed;
}

.sp1_sportsbook {
    background-image: url('../img/general/girl_poker_chips.jpg');
}

.span_poker {
    background-image: url('../img/general/sp_13.jpg');
}

.span_about {
    background-image: url('../img/general/sp_16.jpg');
}
.span_about_2 {
    background-image: url('../img/general/sp_17.jpg');
}
.restrictions{
    position: absolute;
    bottom:0;
    right: 10%;
    z-index: 12;
    color: $blanco;
    text-shadow: 0 0 5px $base;
}