.redes {
    position: fixed;
    bottom: 60%;
    right: 0%;
    font-size: 1.5rem;
    text-align: center;
    z-index: 123;
    width: 50px;
    box-shadow: 0 0 5px #000;
    background: $base_to_dark;
    z-index: 12123129898;
}
.navbar-toggler {
    position: absolute;
    right: 10%;
    top: 7%;
}