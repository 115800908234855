$blanco:#fff;
$base:#000;
$link:#af8050;
$green:#02251d;
$brown:#705937;
$grey:#2b2b2b;
//gradientes
$base_to_dark: linear-gradient(to bottom, $base 0%,darken($base,20) 100%);
$btns: linear-gradient(to bottom, #c4a464 0%,#815016 100%);
$btns_hover: linear-gradient(to bottom,#815016 0%, #c4a464 100%);
$img_after:linear-gradient(to bottom, lighten($base,30) 1%,$base 100%);
$gradiant_blanco:linear-gradient(to bottom, $blanco 1%,darken($blanco,30) 100%);
$gradiant_blanco_hover:linear-gradient(to bottom, darken($blanco,30) 1%,$blanco 100%);

