.span_online_casino ,.span_online_casino_2,.span_bonus{
    @include img_bg;
    min-height: 450px;
    background-image: url('../../img/general/sp_6.jpg');
}
.span_online_casino_2{
    background-image: url('../../img/general/sp_7.jpg');
}
.span_bonus{
    background-image: url('../../img/general/sp_14.jpg');
}
.slider_video{
    overflow: hidden;
    position: relative;
    height: 100vh;
}
.text_slider{
    position: relative;
    z-index: 123;
    height: 100vh;
    width: 100%;
    background:rgba( $base,.65);
    display: flex;
    align-items: center;
    color: $blanco;
    justify-content: center;
    text-align: center;
}
video {
    position: absolute;
    top: 0 ;
    left: 0;

}
