.bandera {
    max-width: 40px;
}

.traduccion_movil {
    display: none;
    position: absolute;
    top:0%;
    width: 25%;
    right: 7%;

}

@media only screen and (max-width: 600px) {
    .traduccion_movil {
        display: block;
    }
}