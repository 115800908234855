.img_promo{
    @include promo_img;
    display: flex;
    align-items: center;
    position: relative;
}

.sports_welcome_100{
    background-image: url('../../img/promos/promos_page/100_welcome_sports.jpg');
}
.crypto_welcome_100{
    background-image: url('../../img/promos/promos_page/100_sports_welcome_crypto.jpg');
}
.sports_reload_50{
    background-image: url('../../img/promos/promos_page/50_sports_reload.jpg')
}
.sports_reload_25{
    background-image: url('../../img/promos/promos_page/25_reload_sports.jpg')
}

.casino_welcome_200{
    background-image: url('../../img/promos/promos_page/200_casino_welcome.jpg')
}
.casino_welcome_100{
    background-image: url('../../img/promos/promos_page/100_casino_reload.jpg')
}
.rebate_horse{
    background-image: url('../../img/promos/promos_page/8_rebate.jpg')
}

.online_casino_1{
    background-image: url('../../img/promos/online_casino/200_welcome.jpg')
}
.online_casino_2{
    background-image: url('../../img/promos/online_casino/reload_bonus.jpg')
}
.online_casino_3{
    background-image: url('../../img/promos/online_casino/rebate.jpg')
}





