.slider {
    min-height: 35vh;
    @include img_bg;
}

//home

.slider1 {
    background-image: url('../../img/slider/home/slider1.jpg');
}

.slider2 {
    background-image: url('../../img/slider/home/slider2.jpg');
}

.slider3 {
    background-image: url('../../img/slider/home/slider3.jpg');
}

@media only screen and (max-width: 600px) {
    .slider1 {
       background-position: left;
    }
    
  }

//paginas
.slider_sportsbook {
    background-image: url('../../img/slider/pages/SPORTSBOOK.jpg');
}

.slider_online_casino {
    background-image: url('../../img/slider/pages/online_casino.jpg');
}

.slider_racing {
    background-image: url('../../img/slider/pages/racing.jpg');
}
.slider_live_betting {
    background-image: url('../../img/slider/pages/live_betting.jpg');
}
.slider_live_casino{
    background-image: url('../../img/slider/pages/live_casino.jpg');
}
.slider_bonus{
    background-image: url('../../img/slider/pages/bonus.jpg');
}
.slider_about{
    background-image: url('../../img/slider/pages/about-us.jpg');
}
.slider_poker{
    background-image: url('../../img/slider/pages/POKER.jpg');
}
.slider_contact{
    background-image: url('../../img/slider/pages/contact.jpg');
}